import "../../style/login-page.css";
import "../../style/main-style.css"
import createReactClass from "create-react-class";
import React from "react";
import AuthenticationManager from "../../database/authentication";
import TermsAndConditions from "./terms-and-conditions.jsx";
import MessageBox from "../libs-common/message-box";
import loadingDiv from "../libs-common/loading-div";

var LoginForm = createReactClass({
  getInitialState: function () {
    let _auth_manager = new AuthenticationManager();
    return {
      register: false,
      email: "",
      password: "",
      password_2: "",
      email_format_ok: true,
      password_pass_criteria: true,
      password_match: true,
      auth_manager: _auth_manager,
      show_terms_and_conditions: false,
      terms_agree: false,
      show_message: false,
      message_title: "",
      message_content: "",
      loginInProgress: false,
      resetPassword: false,
    };
  },
  onKeyPress: function (event) {
    if (event.key === "Enter") {
      if (this.inputValidation()) {
        this.singInOrLogInFunction();
      }
    }
  },
  singInOrLogInFunction: function () {
    if (this.inputValidation()) {
      if (this.state.register) {
        this.setState({ loginInProgress: true });
        this.state.auth_manager.singIn(this.state.email, this.state.password, this.performLogin);
      } else {
        this.setState({ loginInProgress: true });
        this.state.auth_manager.logIn(this.state.email, this.state.password, this.performLogin);
      }
    }
  },
  performLogin: function (user) {
    const db_object = {};
    db_object["email"] = this.state.email;
    db_object["pass"] = this.state.password;
    if (user && user.error && user.error.message) {
      db_object["error_code"] = user.error.code;
      db_object["error_message"] = user.error.message;
      this.props.dbHandler.addNewLogin(db_object, false);
      //TODO: Write custom messages according to error code.
      //let message = getMessageFromErrorCode(user.code);
      this.setState({
        show_message: true,
        message_title: "Greška!",
        message_content: <>Došlo je do greške prilikom logovanja.<br /> {(user.error && user.error.message) ? user.error.message : ""}</>,
        loginInProgress: false,
      });
    } else if (user && user.id) {
      this.setState({ loginInProgress: false });
    } else {
      this.props.dbHandler.addNewLogin(db_object, false);
      this.setState({
        show_message: true,
        message_title: "Greška!",
        message_content: <>Došlo je do greške prilikom logovanja.<br /> Greška: nepoznata</>,
        loginInProgress: false,
      });
    }
  },
  onClick: function (event) {
    switch (event.target.name) {
      case "btnAction":
        this.singInOrLogInFunction();
        break;
      case "btnChangeMod":
        let register_val = this.state.register;
        this.setState({
          email: "",
          password: "",
          password_2: "",
          register: !register_val,
          email_format_ok: true,
          password_pass_criteria: true,
          password_match: true,
          terms_agree: false,
        });
        break;
      case "terms":
        const show_terms_value = this.state.show_terms_and_conditions;
        this.setState({
          show_terms_and_conditions: !show_terms_value,
        });
        break;
      case "terms_agree":
        this.setState({
          terms_agree: event.target.checked,
        });
        break;
      default:
        break;
    }
  },
  onChange: function (event) {
    let value = event.target.value;
    let input_name = event.target.name;
    this.setState({ [input_name]: value });
  },
  inputValidation: function () {
    let _email_format_ok = true;
    let _password_pass_criteria = true;
    let _password_match = true;
    _email_format_ok = this.validateEmail(this.state.email);

    if (this.state.register) {
      if (this.state.password !== this.state.password_2) {
        _password_match = false;
      } else {
        _password_pass_criteria = this.validatePassword(this.state.password);
      }
    }

    this.setState({
      email_format_ok: _email_format_ok,
      password_pass_criteria: _password_pass_criteria,
      password_match: _password_match,
    });
    if (_email_format_ok && _password_pass_criteria && _password_match) {
      return true;
    } else {
      return false;
    }
  },
  validateEmail: function (email) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  validatePassword: function (password) {
    //Minimum eight characters, at least one uppercase letter,
    //one lowercase letter, one number and one special character
    let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return re.test(password);
  },
  render: function () {
    if (this.state.show_terms_and_conditions) {
      return <TermsAndConditions onClick={this.onClick} />;
    }
    else if (this.state.show_message) {
      return (
        <>
          <MessageBox
            title={this.state.message_title}
            content={this.state.message_content}
            onClick={() => { this.setState({ show_message: false, message_title: "", message_content: "" }) }} />
        </>
      );
    }
    else if (this.state.loginInProgress) {
      return (loadingDiv());
    }
    else if (this.state.resetPassword) {
      return (
        <div className="login-page-form border-radius">
          <div className="box">
            <h1>Promena lozinke</h1>
            {/* Ukoliko neko polje nije popunjeno ili pogresan unos, postojecu klasu zamenis sa form-input-red koji daje crveni boder.
                      Isto tako i za register form */}
            <p>Unesite Vašu e-mail adresu.</p>
            <p>Link za promenu lozinke će zatim biti poslat.</p>
            <input
              id="email"
              type="text"
              name="email"
              placeholder="Unesite vašu e-mail adresu"
              className="form-input"
              onKeyPress={this.onKeyPress}
              onChange={this.onChange}
              value={this.state.email}
            />
            <div className="button-box">
              <button
                name="btnChangeMod"
                className="form-button"
                onClick={() => { this.setState({ resetPassword: false, }) }}
              >
                Nazad{" "}
              </button>
              <button
                name="btnAction"
                className="form-button"
                onClick={
                  () => {
                    this.setState({
                      loginInProgress: true,
                      resetPassword: false,
                    });
                    this.state.auth_manager.resetPasswordFroEmail(this.state.email,
                      (result) => {
                        this.setState({
                          loginInProgress: false,
                          show_message: result ? true : false,
                          message_title: result ? "Greška!" : "",
                          message_content: result ? result : "",
                        });
                      });
                  }
                }>Pošalji
              </button>
            </div>
          </div>
        </div >
      );
    }
    else if (!this.state.register) {
      return (
        <div className="login-page-form border-radius">
          <div className="box">
            <h1>Uloguj se</h1>
            {/* Ukoliko neko polje nije popunjeno ili pogresan unos, postojecu klasu zamenis sa form-input-red koji daje crveni boder.
                        Isto tako i za register form */}
            <input
              id="email"
              type="text"
              name="email"
              placeholder="Unesite vašu e-mail adresu"
              className="form-input"
              onKeyPress={this.onKeyPress}
              onChange={this.onChange}
              value={this.state.email}
            />
            <input
              id="password"
              type="password"
              name="password"
              placeholder="Unesite vašu šifru"
              className="form-input"
              onKeyPress={this.onKeyPress}
              onChange={this.onChange}
              value={this.state.password}
            />
            <a className="forgot text-muted" href onClick={() => { this.setState({ resetPassword: true, }) }}>
              Zaboravili ste lozinku?
            </a>
            <div className="button-box">
              <button
                name="btnChangeMod"
                className="form-button"
                onClick={this.onClick}
              >
                Registruj se{" "}
              </button>
              <button
                name="btnAction"
                className="form-button"
                onClick={this.onClick}>Uloguj se
              </button>
            </div>
            <p>Nemate nalog?</p>
            <p>Kliknite na dugme za registraciju</p>
          </div>
        </div >
      );
    }
    else {
      return (
        <div className="login-page-form border-radius">
          <div className="box">
            <h1>Registruj se</h1>
            <input
              id="email"
              type="text"
              name="email"
              placeholder="Unesite vašu e-mail adresu"
              className={("form-input " + (this.state.email_format_ok ? "" : "form-input-red"))}
              onKeyPress={this.onKeyPress}
              onChange={this.onChange}
              value={this.state.email}
            />
            <div className="extra-info">
              <input
                id="password"
                type="password"
                name="password"
                placeholder="Unesite lozinku"
                className={("form-input " + (this.state.password_pass_criteria ? "" : "form-input-red"))}
                onKeyPress={this.onKeyPress}
                onChange={this.onChange}
                value={this.state.password}
              />
              <>
                <i className="icon-info-sign">
                  i
                  <span className="extra-info-span border-radius">
                    <p>Lozinka mora sadržati:</p>
                    <p>- najmanje 8 karaktera</p>
                    <p>- bar jedan specijalni karakter</p>
                    <p>- bar jedno veliko slovo</p>
                    <p>- bar jedan broj</p>
                  </span>
                </i>
              </>
            </div>
            <input
              id="password_2"
              type="password"
              name="password_2"
              placeholder="Ponovite lozinku"
              className={("form-input " + (this.state.password_match ? "" : "form-input-red"))}
              onKeyPress={this.onKeyPress}
              onChange={this.onChange}
              value={this.state.password_2}
            />
            <div className="regster-checkbox">
              <input
                type="checkbox"
                id="terms_agree"
                name="terms_agree"
                onClick={this.onClick}
              />
              <label htmlFor="terms_agree" class="label custom-checkbox">
                Slažem se sa &nbsp;{" "}
              </label>
              <a
                id="terms"
                name="terms"
                href
                onClick={this.onClick}
                className="link-to-terms"
              >
                uslovima korišćenja ovog portala.
              </a>
            </div>
            <div className="button-box">
              {/* Ukoliko nisu sva polja popunjena button-u dodeli .disabled klasu inace ima .form-button klasu*/}
              <button
                name="btnChangeMod"
                className="form-button"
                onClick={this.onClick}>Uloguj se</button>
              <button
                name="btnAction"
                className={"form-button " + (this.state.terms_agree ? "" : "disabled")}
                disabled={!this.state.terms_agree}
                onClick={this.onClick}>Registruj se</button>
            </div>
          </div>
        </div>
      );
    }
  },
});

export default LoginForm;
