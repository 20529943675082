import '../../style/portal-page.css';
import createReactClass from 'create-react-class';
import React from 'react';
import Loader from "../libs-common/Loader";
import Settings from './settings-page';
import BugReport from './bug-report-page';

var PortalPage = createReactClass({
    getInitialState: function () {
        this.props.dbHandler.getPortalLinksDB(this.onDBFinish)
        return {
            user: this.props.user,
            dbHandler: this.props.dbHandler,
            dbLoading: true,
            portalLinks: [],
            selectedMenu: "",
        };
    },
    componentDidUpdate: function (prevProps) {
        if (this.props.selectedMenu !== prevProps.selectedMenu) {
            this.setState({
                selectedMenu: this.props.selectedMenu,
            });
        }
    },
    componentDidMount: function () {
        window.scrollTo(0, 0);
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);
    },
    componentWillUnmount: function () {
        window.removeEventListener('popstate', this.onBackButtonEvent);
    },
    onBackButtonEvent: function (event) {
        if (this.state.selectedMenu) {
            event.preventDefault();
            if (this.props.backFunction) {
                this.props.backFunction();
            }
            else {
                console.log("Back function is not set!");
            }
        }
    },
    onDBFinish: function (_portalLinks) {
        this.setState({
            dbLoading: false,
            portalLinks: _portalLinks
        });
    },
    onItemClick: function (event) {
        let link = event.currentTarget.id;
        const button = event.button
        if (link) {
            switch (link) {
                case "bug_report":
                    if (button === 0) {
                        this.props.onSelectionChange("bug_report");
                    }
                    break;
                case "settings":
                    if (button === 0) {
                        this.props.onSelectionChange("settings");
                    }
                    break;
                case "admin_god":
                    if (button === 0) {
                        link = "http://localhost:3002/";
                        window.open(link, "_blank");
                    }
                    break;

                default:
                    // if (!isRel()) {
                    //     link = "http://localhost:3001/";
                    // }
                    //middle mouse button
                    if (button === 1) {
                        window.open(link, "_blank")
                    }
                    //left mouse button
                    else if (button === 0) {
                        window.location.href = link;
                    }

                    break;
            }
        }
    },
    generateHexagonItems: function () {
        let hexagonItems = [];
        let portalLink = null;
        let portalLinksMap = {};
        this.state.portalLinks.forEach(linkElement => {
            portalLinksMap[linkElement.index] = linkElement;
        });
        for (let index = 0; index < 14; index++) {
            if (portalLinksMap[index]) {
                portalLink = portalLinksMap[index];
                hexagonItems.push(
                    <div className="item" id={portalLink.link}
                        onMouseDown={this.onItemClick}>
                        <div className="hexagon">
                            <img src={portalLink.img.normal} alt={portalLink.img.alt} />
                            <p>{portalLink.name}</p>
                        </div>
                    </div >
                );
            }
            else {
                hexagonItems.push(
                    <div className="item">
                        <div className="hexagon-empty"></div>
                    </div>
                );
            }
        }
        const retVal = <div className="portal-page-container">{hexagonItems}</div>
        return retVal;
    },
    generateContent: function () {
        let content;
        switch (this.state.selectedMenu) {
            case "bug_report":
                content = <BugReport
                    portalLinks={this.state.portalLinks}
                    backFunction={this.props.backFunction}
                    user={this.props.user} />
                break;
            case "settings":
                content = <Settings />
                break;

            default:
                content = this.generateHexagonItems()
                break;
        }

        return content;
    },
    render: function () {
        if (this.state.dbLoading) {
            return (
                <div className="portal-page-loading">
                    <Loader />
                </div>
            );
        }
        else {
            return (

                this.generateContent()
            )
        }
    }
});

export default PortalPage;
