import '../../style/verification.css';
//import AuthenticationManager from '../../database/authentication.js'
var createReactClass = require('create-react-class');
var React = require('react');

var AdminVerificationFail = createReactClass({

    onClick: function (event) {
        switch (event.target.name) {
            case "login_back":
                if (this.props.initiateLogOut) {
                    this.props.initiateLogOut();
                } else {
                    console.log("Logout function is not defined!");
                }
                break;
            default:
                break;
        }
    },
    render: function () {
        return (
            <div class='verification-fail-container border-radius border-red'>
                <div class='verification-fail-content'>
                    <div class="verification-fail-header">
                        <h3>Nemate dodeljene permisije!</h3>
                    </div>
                    <div class="verification-fail-body">
                        <p>Nemate dodeljene privilegije da bi ste pristupili sadržaju portala. </p>
                        <p>Molimo Vas, kontaktirajte adminitratore, putem sledece e-mail adrese, da bi Vam privilegije bile dodeljene:</p>
                        <p>admin@demango.rs</p>
                        <br />
                        <a id="login_back" href name="login_back" onClick={this.onClick} className="link-to-terms">Povratak na stranicu za logovanje</a>
                    </div>
                </div>
            </div>
        );
    }
});

export default AdminVerificationFail;