import '../../style/button-log-out.css';
import createReactClass from 'create-react-class';
import React from 'react';

var ButtonLogOut = createReactClass({
    render: function () {
        return (
            <div className="log-out-button-container">
                {this.props.user ?
                    <>
                        <h4 className="">{this.props.user.email}</h4>
                        <button className="btn-style" onClick={this.props.onClick}>Izloguj se</button>
                    </> : <></>}

            </div>
        )
    }
});

export default ButtonLogOut;
