import Admin from '../libs/model/admin.ts';
import PortalLink from "../libs/model/portalLink.ts";
import { db } from './firebase.js';
import firebase from './firebase.js';

class DBHandler {
    initializeVersionListener(onChangeFunction) {
        const refVersion = db.collection("appVersions").doc("deMangoAdmin");;
        refVersion.onSnapshot((querySnapshot) => {
            const retObj = querySnapshot.data();
            let version = 0;
            if (retObj && retObj.version) {
                version = retObj.version
            }
            if (onChangeFunction) {
                onChangeFunction(version);
            }
        });
    }

    async getAdminByEmailDB(admin_email) {
        let admin = null;
        const refAdmin = firebase.firestore().collection("admins").doc(admin_email);
        await refAdmin.get()
            .then(function (docRef) {
                admin = new Admin();
                admin.loadFromJson(docRef.data());
            })
            .catch(function (error) {
                console.error("Error adding admin: ", error);
                return false;
            });

        return admin;
    }

    async createAdminInDB(onDbFinish) {
        const createAdminInDBFunc = firebase.functions().httpsCallable('createAdminInDB');
        let adminObj = new Admin();
        try {
            const result = await createAdminInDBFunc();
            if (result.error) {
                console.log(result);
            }
            else if (result.data && result.data.admin) {
                adminObj.loadFromJson(result.data.admin)
            }
            else {
                console.log("There is no Data in order DB respond!");
            }
            if (onDbFinish) {
                onDbFinish(adminObj);
            }
        }
        catch (ex) {
            console.log(ex);
            if (onDbFinish) {
                onDbFinish(adminObj);
            }
        }
        return true;
    }

    async updateAdminDB(admin) {
        let admin_json = admin.toJason();
        const refAdmin = firebase.firestore().collection("/admins").doc(admin_json.email);
        await refAdmin.update(admin_json)
            .then(function () {
                console.log("Admin updated in DB");
            })
            .catch(function (error) {
                console.error("Error updating admin: ", error);
                return false;
            });

        return true;
    }

    async addNewLogin(infoObj, successfully) {
        try {
            const addNewLoginFunction = firebase.functions().httpsCallable('addNewLogin');
            await fetch('https://json.geoiplookup.io/') // TODO: Move this to server side. 
                .then(response => response.json())
                .then((jsonData) => {
                    Object.keys(jsonData)
                        .forEach(key => infoObj[key] = jsonData[key]);
                })
                .catch((error) => {
                    console.error(error)
                })
            addNewLoginFunction({successfully: successfully, info: infoObj});
        }
        catch (ex) {
            console.log(ex);
        }
    }

    async getPortalLinksDB(onDbFinishFunction) {
        let portalLinks = [];
        const getPortalLinksByUserFunc = firebase.functions().httpsCallable('getPortalLinksByUser');
        getPortalLinksByUserFunc()
            .then((result) => {
                if (result.data) {
                    if (result.data.error) {
                        console.log(result.data.error);
                    }
                    else if (result.data.links) {
                        Object.values(result.data.links).forEach((portalLinkJSON) => {
                            let portalLinkObj = new PortalLink();
                            portalLinkObj.loadFromJson(portalLinkJSON);
                            portalLinks.push(portalLinkObj);
                        })
                    }
                }
                if (onDbFinishFunction) {
                    onDbFinishFunction(portalLinks);
                } else {
                    console.log("On db finish function is not set!");
                }
            })
            .catch((result) => {
                console.log(result);
            })
    }

    async getCustomToken(onDbFinish) {
        const getCustomTokenFunc = firebase.functions().httpsCallable('getCustomToken');
        getCustomTokenFunc()
            .then((result) => {
                if (result.data.token) {
                    if (onDbFinish) {
                        onDbFinish(result.data.token);
                    }
                }
                else if (result.data.error) {
                    console.log(result.data.error);
                    if (onDbFinish) {
                        onDbFinish(null);
                    }
                }
                else {
                    console.log("Token and error are null!");
                    if (onDbFinish) {
                        onDbFinish(null);
                    }
                }
            })
            .catch((result) => {
                console.log("Error getting Custom Session Token!");
                console.log(result);
                if (onDbFinish) {
                    onDbFinish(null);
                }
            });
    }
}

export default DBHandler;