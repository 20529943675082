import '../../style/common/button-bar.css'
import React from 'react';
var createReactClass = require('create-react-class');

/**
 * @property {string} containerSize
 * determines containers width in percent
 * default value: 100
 * other values: 70 and 50

 * CR: Kada se sve do gornjeg CR komentara prebaci na drugu klasu, treba da ostane property buttonSettings, kao kolekcija instanci objekata klase ButtonSettings.
 * Umesto svega ovoga prosledjujemo kolekciju instanci objekata settings. [settingsObj1, settingsObj2....]
 */
var ButtonBar = createReactClass({
    getInitialState: function () {
        return {
            containerSize: this.getContainerSize(this.props.containerSize),
            buttonSettings: this.props.buttonSettings,
        };
    },
    getContainerSize: function (containerSize) {
        switch (containerSize) {
            case "70":
                return "button-container-m";
            case "50":
                return "button-container-s";
            default:
                return "button-container-l";
        }
    },

    componentDidUpdate: function (prevProps) {
        if (prevProps.containerSize !== this.props.containerSize) {
            this.setState({ containerSize: this.getContainerSize(this.props.containerSize), });
        }
        if (this.props.buttonSettings.length !== prevProps.buttonSettings.length) {
            this.setState({ buttonSettings: this.props.buttonSettings, });
        }
        else{
            let updateState = false;
            this.props.buttonSettings.forEach((settings, index) => {
                if(!settings.isEqual(prevProps.buttonSettings[index])){
                    updateState = true;
                }
            });
            if(updateState){
                this.setState({ buttonSettings: this.props.buttonSettings, });
            }
        }
    },
render: function () {
    return (
        <div className={("button-container " + (this.state.containerSize))}>
            {this.state.buttonSettings && this.state.buttonSettings.length > 0 ?
                this.state.buttonSettings.map((settings) => {
                    return (
                        <button
                            id={settings.id}
                            className={("button " + (settings.style) + " " + (settings.isRed))}
                            onClick={settings.onClick ? settings.onClick : () => console.log("onClick function is not set")}>
                            {settings.content}
                        </button>
                    )
                })
                : null}
        </div>
    );
}
});
/**
* @property {string} content
* determines button content
* @property {string} style
* changes visual display of a button 
* default value: primary (yellow button)
* other values: secondary (gray button) and disabled 
* @property {boolean} isRed
* adds red border and red background to button 
* default value: false
* @property {string} id
* adds id to button 
* @property {function} onClick
* function executed when clicked on button 
 */
export class ButtonSettings {

    constructor(content, style, isRed, id, onClick) {
        this.id = id;
        this._style = this.getButtonStyle(style);
        this._isRed = isRed;
        this.onClick = onClick ? onClick : () => (console.log("Button 'OnClick' function is not set! Button id: " + this.id));
        this.content = content;
    }

    get style() {
        return this._style;
    }
    set style(value) {
        this._style = this.getButtonStyle(value);
    }

    get isRed() {
        return this._isRed ? "button-red" : "";
    }
    set isRed(value) {
        this._isRed = value;
    }

    isEqual(settingsToCompare) {
        if(settingsToCompare){
            if(this.id !== settingsToCompare.id){
                return false;
            }
            if(this._style !== settingsToCompare._style){
                return false;
            }
            if(this._isRed !== settingsToCompare._isRed){
                return false;
            }
            if(this.onClick !== settingsToCompare.onClick){
                return false;
            }
            if(this.content !== settingsToCompare.content){
                return false;
            }
            return true;
        }
        else{
            return false;
        }
    }

    getButtonStyle(buttonStyle) {
        switch (buttonStyle) {
            case "secondary":
                return "button-cancel";
            case "disabled":
                return "button-disabled";
            default:
                return "button-default";
        }
    }
}

export { ButtonBar, ButtonSettings as default }