/* eslint-disable import/no-named-as-default */
/* eslint-disable require-jsdoc */
import Image from "./image";
/**
 * Placeholder class for Order attributes.
 */
export class PortalLink {
    index: number;
    id: string;
    name: string;
    appliedTypes: Array<number>;
    img: Image;
    link: string;

    constructor() {
        this.index = 0;
        this.id = "";
        this.name = "";
        this.appliedTypes = [];
        this.img = new Image();
        this.link = "";
    }

    loadFromJson(jsonObject: any) {
        if (jsonObject) {
            if (jsonObject.index) {
                this.index = jsonObject.index;
            }
            if (jsonObject.id) {
                this.id = jsonObject.id;
            }
            if (jsonObject.name) {
                this.name = jsonObject.name;
            }
            if (jsonObject.appliedTypes) {
                this.appliedTypes = jsonObject.appliedTypes;
            }
            if (jsonObject.img) {
                const _img = new Image();
                _img.loadFromJson(jsonObject.img);
                this.img = _img;
            }
            if (jsonObject.link) {
                this.link = jsonObject.link;
            }
        }
    }

    toJason(): any {
        return {
            index: this.index,
            id: this.id,
            name: this.name,
            appliedTypes: this.appliedTypes,
            img: this.img.toJason(),
            link: this.link
        };
    }
}

export default PortalLink;
