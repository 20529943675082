import './style/login-page.css';
import './style/main-style.css';
import './App.css';
import React from 'react';
import EmailVerificationFail from './libs/login_page/email-verification-fail.jsx';
import AdminVerificationFail from './libs/login_page/admin-verification-fail.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import PortalPage from './libs/clients_page/portal-page.jsx';
import ButtonLogOut from './libs/libs-common/button-log-out.jsx';
import LoginForm from "./libs/login_page/login-form";
import AuthenticationManager from './database/authentication';
// import AutomaticSessionHandler from "./database/automatic-session-handler";
import loadingDiv from "./libs/libs-common/loading-div";
import enumAdminType from "./libs/model/enums/adminType";
import AppConnectionState from './libs/libs-common/app-connection-state';
import packageJason from "../package.json";
import dateToFullString from './libs/libs-common/DateTimeHelper';
import DBHandler from './database/database-handler-admin';
import AppVersionControl from './libs/clients_page/AppVersionControl';

const createReactClass = require('create-react-class');

const App = createReactClass({
  getInitialState: function () {
    const _dbHandler = new DBHandler();
    let _auth_manager = new AuthenticationManager();
    _auth_manager.addAuthStateChangeFunction(this.onAuthStateChanged);
    return {
      user: null,
      auth_manager: _auth_manager,
      auth_initialized: false,
      loading: false,
      selectedMenu: "",
      dbHandler: _dbHandler,
      showSideMenu: false,
    };
  },
  onAuthStateChanged: function (user) {
    if (user) {
      this.setState({
        user: user,
        auth_initialized: true
      });
    }
    else {
      this.setState({
        user: null,
        auth_initialized: true
      });
    }
  },
  onLogin: function (user) {
    this.setState({ user: user });
  },
  initiateLogOut: function () {
    this.setState({ showSideMenu: false, });
    this.state.auth_manager.logOut();
  },
  initiateEmailResend: function () {
    this.state.auth_manager.resendEmail();
    this.makeFakeReload();
  },
  makeFakeReload: async function () {
    this.setState({ loading: true })
    await this.sleep(250);
    this.setState({ loading: false })
  },
  sleep: async function (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },
  onSelectionChange: function (newSelectedMenu) {
    this.setState({ selectedMenu: newSelectedMenu });
  },
  backFunction: function () {
    this.onSelectionChange("");
  },
  onClick: function (event) {
    switch (event.target.id) {
      case "portal-open":
        this.setState({ showSideMenu: event.target.checked })
        break;

      default:
        break;
    }
  },
  render: function () {
    let content;
    if (!this.state.auth_initialized || this.state.loading) {
      content = (loadingDiv());
    }
    else if (this.state.user) {
      let is_user_verified = this.state.auth_manager.isUserVerified(this.state.user);
      if (!is_user_verified) {
        content = <EmailVerificationFail initiateLogOut={this.initiateLogOut} initiateEmailResend={this.initiateEmailResend} />;
      }
      else if (this.state.user.type === enumAdminType.UNIDENTIFIED) {
        content = <AdminVerificationFail initiateLogOut={this.initiateLogOut} />;
      }
      else if (is_user_verified && this.state.user.type !== enumAdminType.UNIDENTIFIED) {
        content = <PortalPage
          user={this.state.user}
          onSelectionChange={this.onSelectionChange}
          selectedMenu={this.state.selectedMenu}
          backFunction={this.backFunction}
          dbHandler={this.state.dbHandler} />;
      }
      else {
        content = <AdminVerificationFail initiateLogOut={this.initiateLogOut} />;
      }
    }
    else {
      content = <LoginForm
        onLogin={this.onLogin}
        dbHandler={this.state.dbHandler} />;
    }
    return (
      <div className="site-content-container">
        <div className="login-page-container">
          <AppVersionControl dbHandler={this.state.dbHandler} currentVersion={packageJason.buildDate} />
          <AppConnectionState />
          <div className="login-page-header">
            {this.state.user ?
              <>
                <input type="checkbox" id="portal-open" checked={this.state.showSideMenu} onClick={this.onClick} />
                <label htmlFor="portal-open" className="portal-open-aside" >
                  <i><FontAwesomeIcon icon={faBars} /></i>
                </label>
              </> : null}
            {this.state.selectedMenu ? <p><button className="back-button" onClick={() => { this.setState({ selectedMenu: "" }) }}>
              <FontAwesomeIcon icon={faArrowCircleLeft} />
            </button></p> : null}
            <p> <b>deMango</b>  portal</p>
            <div className="logged-in-user">
              <ButtonLogOut onClick={this.initiateLogOut} user={this.state.user} />
            </div>
          </div>
          <div className="login-page-body">
            {content}
          </div>
          {(this.state.user && this.state.user.type === enumAdminType.SUPER_ADMIN) ?
            <div className="build-date"><p>Build date: <br />{dateToFullString(packageJason.buildDate)} </p></div> : null}
        </div>
      </div>
    );
  }
});

export default App;
