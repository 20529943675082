import DBHandler from './database-handler-admin.js';
import firebase from './firebase.js';
import Cookies from 'js-cookie';

var authStateChangeFunction = null;

class AuthenticationManager {

    static appDomain = this.getDomain();

    constructor() {
        this.dbHandler = new DBHandler();
        firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
        const token = AuthenticationManager.getSessionToken();
        if (token) {
            this.loginWithToken(token);
        }
    }

    addAuthStateChangeFunction(func) {
        authStateChangeFunction = func;
    }

    static getDomain() {
        const hrefStr = String(window.location.href);
        if (hrefStr.includes("internal-portal-de-mango")) {
            return ".internal-portal-de-mango.rs";
        }
        else if (hrefStr.includes("localhost")) {
            return "localhost";
        }
        else {
            return "";
        }
    }

    async singIn(email, password, onLoginFunction) {
        AuthenticationManager.setLoginCookie();
        let db_data = null;
        try {
            const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
            db_data = userCredential.user;
            var user = firebase.auth().currentUser;
            user.sendEmailVerification();
            await this.dbHandler.getCustomToken(AuthenticationManager.updateSessionCookies);
            this.dbHandler.createAdminInDB();
            this.dbHandler.addNewLogin({}, true);
        }
        catch (ex) {
            console.log("Error while singin: " + ex);
            db_data = { error: ex };
        }
        //TODO: if(db_data is user)
        if (onLoginFunction) {
            onLoginFunction(db_data);
        }
        else {
            console.log("'On sing in' function is not defined!");
        }
        AuthenticationManager.setLoginCookie(true);
    }

    async logIn(email, password, onLoginFunction) {
        AuthenticationManager.setLoginCookie();
        let db_data = null;
        try {
            const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
            db_data = userCredential.user;
            await this.dbHandler.getCustomToken(AuthenticationManager.updateSessionCookies);
            const admin_obj = await this.dbHandler.getAdminByEmailDB(email);
            this.dbHandler.addNewLogin({}, true);
            db_data = admin_obj;
        }
        catch (ex) {
            console.log("Error while login: " + ex);
            db_data = { error: ex };
        }
        //TODO: if(db_data is user)
        if (onLoginFunction) {
            onLoginFunction(db_data);
        }
        else {
            console.log("'On login' function is not defined!");
        }
        AuthenticationManager.setLoginCookie(true);
    }

    async loginWithToken(token) {
        firebase.auth().signInWithCustomToken(token)
            .then((userCredential) => {
                //const user = userCredential.user;
            })
            .catch((error) => {
                if (error && error.code && error.code === "auth/invalid-custom-token") {
                    this.tryToRenewSessionCookie();
                }
                else {
                    console.log(error.code);
                    console.log(error.message);
                    AuthenticationManager.updateSessionCookies(null);
                    firebase.auth().signOut()
                }
            });
    }

    async tryToRenewSessionCookie() {
        await this.dbHandler.getCustomToken(AuthenticationManager.updateSessionCookies);
        if (!AuthenticationManager.getSessionToken) {
            firebase.auth().signOut();
        }
        else {
            console.log("Session successfully updated.");
        }
    }

    isUserVerified(user_obj) {
        let user = firebase.auth().currentUser;
        if (user_obj) {
            if (user_obj.verified !== user.emailVerified) {
                user_obj.verified = user.emailVerified;
                this.dbHandler.updateAdminDB(user_obj);
            }
        }
        return user.emailVerified;
    }

    getCurrentUser() {
        return firebase.auth().currentUser;
    }

    isLoggedIn() {
        if (firebase.auth().currentUser) {
            return true;
        }
        else {
            return false;
        }
    }

    static updateSessionCookies(token) {
        const cookieName = "demango-internal-session";
        const path = "/";
        if (token) {
            Cookies.remove(cookieName, { domain: AuthenticationManager.appDomain, path: path });
            Cookies.set(cookieName, token, { domain: AuthenticationManager.appDomain, path: path });
        }
        else {
            Cookies.remove(cookieName, { domain: AuthenticationManager.appDomain, path: path });
        }
    }

    static getSessionToken() {
        const cookieName = "demango-internal-session";
        const path = "/";
        const cookie = Cookies.get(cookieName, { domain: AuthenticationManager.appDomain, path: path });
        return cookie;
    }

    static setLoginCookie(remove = false) {
        if (remove) {
            Cookies.remove("loginInProgress");
        }
        else {
            Cookies.set("loginInProgress", true);
        }
    }

    static getLoginCookie() {
        return Cookies.get("loginInProgress");
    }

    async onAuthStateChanged(user_db) {
        if (user_db && user_db.email) {
            const token = AuthenticationManager.getSessionToken();
            const loginCookie = AuthenticationManager.getLoginCookie();
            if (!token && !loginCookie) {
                firebase.auth().signOut();
                // console.log("Uradio logout govno");
            }
            let _dbHandler = new DBHandler();
            let admin_obj = await _dbHandler.getAdminByEmailDB(user_db.email);
            if (authStateChangeFunction) {
                authStateChangeFunction(admin_obj);
            }
            else {
                console.log("Auth state change function is not set!");
            }
        }
        else {
            if (authStateChangeFunction) {
                authStateChangeFunction(null);
            }
            else {
                console.log("Auth state change function is not set!");
            }
        }
    }

    async logOut() {
        firebase.auth().signOut()
            .then((userCredential) => {
                AuthenticationManager.updateSessionCookies(null);
                if (this.onLogoutFunction) {
                    this.onLogoutFunction(false);
                }
                else {
                    console.log("On logout function is not set!");
                }
            })
            .catch((error) => {
                console.log(error);
                //var errorCode = error.code;
                //var errorMessage = error.message;
                //this.onLogoutFunction(error);
            });
    }

    async resendEmail() {
        var user = firebase.auth().currentUser;
        user.sendEmailVerification()
    }

    async resetPasswordFroEmail(email, onDbFinish) {
        try {
            if (email) {
                await firebase.auth().sendPasswordResetEmail(email);
                console.log(email);
            }
            if (onDbFinish) {
                onDbFinish();
            }
        }
        catch (error) {
            console.log(error);
            // var errorCode = error.code;
            var errorMessage = error.message;
            if (onDbFinish) {
                onDbFinish(errorMessage);
            }
        }
    }
}

export default AuthenticationManager;