import '../../style/common/input.css'
import React from 'react'; 
var createReactClass = require('create-react-class');

/**
 * @property {string} wrapperSize
 * determines wrapper's width in percent
 * default value: 100
 * other values: 70 and 50
 * @property {string} inputFieldSize
 * determines input field's width in percents
 * default value: 100
 * other values: 70 and 50
 * @property {string} label
 * describes what to enter in input field
 * @property {string} id
 * specifies the unique id of an element
 * @property {string} name
 * specifies the name of an input element
 * should be the same as id
 * @property {string} htmlFor
 * refers to the id of the element label is associated with
 * should be the same as id
 * @property {string} placeholder
 * describes what is expected to be entered in an input field
 * @property {string} type
 * sets the type of an input field
 * default value: text
 * other values: password, textarea
 * @property {boolean} inputDisabled
 * disables the input field - it cannot be clicked
 * default value: false
 * @property {boolean} inputRed
 * adds red border and red background to input field
 * default value: false
 */
 
var Input = createReactClass({
    getInitialState: function () {
        return this.getInitObject();
    },
    getInitObject: function(){
        let _inputDisabled = "input-disabled";
        let _inputRed = "input-red"
        return {
            type: this.props.type ? this.props.type : "text",
            wrapperSize: this.getWrapperSize(this.props.wrapperSize),
            inputFieldSize: this.getInputFieldSize(this.props.inputFieldSize),
            inputDisabled: this.props.inputDisabled ? _inputDisabled : "",
            inputRed: this.props.inputRed ? _inputRed : ""
        };
    },
    getWrapperSize: function (wrapperSize) {
        switch (wrapperSize) {
            case "70":
                return "input-wrapper-m";
            case "50":
                return "input-wrapper-s";
            default:
                return "input-wrapper-l";
        }
    },
    getInputFieldSize: function (fieldSize) {
        switch (fieldSize) {
            case "70":
                return "input-field-m";
            case "50":
                return "input-field-s";
            default:
                return "input-field-l";
        }
    },
    componentDidUpdate: function (prevProps) {
        if (prevProps.wrapperSize !== this.props.wrapperSize ||
            prevProps.inputFieldSize !== this.props.inputFieldSize ||
            prevProps.inputDisabled !== this.props.inputDisabled ||
            prevProps.inputRed !== this.props.inputRed ||
            prevProps.placeholder !== this.props.placeholder) {
            this.setState(this.getInitObject());
        }
    },
    render: function () {
        if (this.state.type === "textarea") {
            return (
                <div className={("input-wrapper " + (this.state.wrapperSize))}>
                    {this.props.label ?
                        <label className="input-label" htmlFor={this.props.htmlFor}>{this.props.label}</label>
                    : null}
                    <textarea
                        onChange={this.props.onChange}
                        name={this.props.name} id={this.props.id}
                        className={"input-field " + ((this.state.inputFieldSize) + ' ' + (this.state.inputDisabled) + ' ' + (this.state.inputRed))}
                        placeholder={this.props.placeholder} 
                    />
                </div>
            );
        }
        else {
            return (
                <div className={("input-wrapper " + (this.state.wrapperSize))}>
                    {this.props.label ? 
                        <label className="input-label" htmlFor={this.props.htmlFor}>{this.props.label}</label> 
                    : null}
                    <input 
                        onChange ={this.props.onChange} 
                        type={this.state.type} 
                        name={this.props.name} id={this.props.id} 
                        className={"input-field " + ((this.state.inputFieldSize) + ' ' + (this.state.inputDisabled) + ' ' + (this.state.inputRed))}
                        placeholder={this.props.placeholder}
                    />
                </div>
            );
        }
    }
});

export default Input