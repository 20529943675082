//import '../../style/portal-page.css';
import '../../style/bug-report-page.css';
import createReactClass from 'create-react-class';
import React from 'react';
import EmailService from '../libs-common/EmailService';
import MessageBox from '../libs-common/message-box';
import Loader from '../libs-common/Loader';
import { ButtonBar, ButtonSettings } from '../libs-common/button-bar';
import '../../style/common/button-bar.css'
import DropDown from '../libs-common/drop-down';
import Input from '../libs-common/input';

var BugReport = createReactClass({
    getInitialState: function () {
        return {
            page: "select",
            browser: "",
            device: "",
            details: "",
            showMessageBox: false,
            loading: false,
            buttonSettings: [
                new ButtonSettings("Prijavi bag", "disabled", false, "bug-report", this.onSubmit ),
            ],
        };
    },
    onChange: function (event) {
        let value = event.target.value;
        let id = event.target.id;
        const validationObject = {
            page: this.state.page,
            browser: this.state.browser,
            device: this.state.device,
            details: this.state.details,
        };
        switch (id) {
            case "page":
                validationObject["page"] = value;
                this.setState({ page: value });
                break;
            case "browser":
                validationObject["browser"] = value;
                this.setState({ browser: value });
                break;
            case "device":
                validationObject["device"] = value;
                this.setState({ device: value });
                break;
            case "details":
                validationObject["details"] = value;
                this.setState({ details: value });
                break;
            default:
                break;
        }
        const buttonSettings = this.state.buttonSettings[0];
        if(this.validateInputs(validationObject)){
            buttonSettings.style = "primary";
        }
        else{
            buttonSettings.style = "disabled";
        }
        this.setState({ buttonSettings: [buttonSettings] });
    },
    validateInputs: function (validationObject) {
        return validationObject.page && validationObject.page !== "select" &&
            validationObject.browser && validationObject.browser !== "" &&
            validationObject.device && validationObject.device !== "" &&
            validationObject.details && validationObject.details.length > 20
    },
    onSubmit: function () {
        let returnObject = {
            page: this.state.page,
            browser: this.state.browser,
            device: this.state.device,
            details: this.state.details,
            email: this.props.user.email,
            user: this.props.user.name,
        };
        this.setState({ loading: true })
        EmailService.emailBug(returnObject, () => {
            this.setState({
                loading: false,
                showMessageBox: true,
                enableSubmitButton: false,
                page: "select",
                browser: "",
                device: "",
                details: "",
            });
        });
    },
    render: function () {
        if (this.state.loading) {
            return (<Loader />);
        }
        else {
            return (
                <div className="bug-report-container">
                    {this.state.showMessageBox ?
                        <MessageBox title="Info" content={<>Hvala na prijavi problema! <br /> Ekipa će uskoro izaći na teren.</>} onClick={this.props.backFunction} /> : null}
                    <DropDown
                        dropdownWrapperSize="70"
                        label="Na kojoj stranici ste primetili bag?"
                        id="page"
                        elements={{
                            select: "Izaberite stranicu",
                            home: "Početna stranica",
                            admin: "Admin",
                            settings: "Podešavanja",
                            delivery: "Dostava",
                            stores: "Lokali",
                            siteTest: "Site test",
                            bugReport: "Prijava bug-ova"
                        }}
                        onClick={this.onChange} />
                    <Input
                        onChange={this.onChange}
                        wrapperSize="70"
                        label="Koji pretraživač ste koristili prilikom testiranja sajta?" htmlFor="browser"
                        name="browser"
                        id="browser"
                        placeholder="Npr. Google Chrome" />
                    <Input
                        onChange={this.onChange}
                        wrapperSize="70"
                        label="Koji uređaj ste koristili prilikom testiranja sajta?" htmlFor="device"
                        name="device"
                        id="device"
                        placeholder="Npr. telefon, računar, tablet..." />
                    <Input
                        onChange={this.onChange}
                        wrapperSize="70"
                        label="Molimo Vas da detaljno navedete korake za reprodukciju baga."
                        htmlFor="details"
                        name="details"
                        id="details"
                        type="textarea"
                        inputFieldSize="100" />
                    <ButtonBar
                        containerSize="100"
                        buttonSettings={this.state.buttonSettings}
                    />
                </div>
            );
        }
    }
});

export default BugReport;