import '../../style/settings-page.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMailBulk, faTrash } from '@fortawesome/free-solid-svg-icons';
import createReactClass from 'create-react-class';
import React from 'react';
// import ButtonBar from '../libs-common/button-bar';
import DropDown from '../libs-common/drop-down';
import Input from '../libs-common/input';
import '../../style/common/button-bar.css'
import '../../style/common/input.css'
import '../../style/common/drop-down.css'

var Settings = createReactClass({
    getInitialState: function () {
        return {
        };
    },
    componentDidUpdate: function (prevProps) {

    },
    render: function () {
        return (
            <div className="settings-page-container">
                <div className="box-container">
                    <div className="box-header">
                        <p>Stranica je u izdradi</p>
                    </div>
                    <div className="box-header">
                        <p>Informacije o korisniku</p>
                    </div>
                    <div className="settings-page-box">
                        <div className="user-info-box">
                            <div className="user-photo-box">
                                <img className="user-photo " src="" alt="" />
                                <div className="edit-and-delete">
                                    <p className="edit-icon"><FontAwesomeIcon icon={faEdit} /></p>
                                    <p className="delete-icon"><FontAwesomeIcon icon={faTrash} /></p>
                                </div>
                            </div>
                            <div className="user-details">
                                <div className="user-details-box">
                                    <p className="edit-icon"><FontAwesomeIcon icon={faEdit} /></p>
                                    <p className="user-name">Ime</p>
                                    <input type="text" className="no-textbox" />
                                </div>
                                <div className="user-details-box">
                                    <p className="edit-icon"><FontAwesomeIcon icon={faEdit} /></p>
                                    <p className="user-surname">Prezime</p>
                                    <input type="text" className="no-textbox" />
                                </div>
                                <div className="user-details-box">
                                    <p className="details-icon"><FontAwesomeIcon icon={faMailBulk} /></p>
                                    <p className="user-email">E-mail adresa</p>
                                </div>
                            </div>
                        </div>
                        {/* <ButtonBar
                            buttonOneStyle="secondary"
                            buttonOneContent="Otkaži"
                            buttonTwoStyle="disabled"
                            buttonTwoContent="Sačuvaj izmene" 
                            onClick1={() => { console.log("Test"); }} /> */}
                    </div>
                </div>
                <div className="box-container">
                    <div className="box-header">
                        <p>Izmena lozinke</p>
                    </div>
                    <Input
                        label="Unesite Vašu trenutnu lozinku"
                        type="password"
                        htmlFor="current-pass"
                        name="current-pass"
                        id="current-pass"
                        placeholder="Trenutna lozinka" />
                    <Input
                        label="Unesite Vašu novu lozinku"
                        type="password"
                        htmlFor="new-pass"
                        name="new-pass"
                        id="new-pass"
                        placeholder="Nova lozinka" />
                    <Input
                        label="Ponovite novu lozinku"
                        type="password"
                        htmlFor="new-pass-repeat"
                        name="new-pass-repeat"
                        id="new-pass-repeat"
                        placeholder="Ponovljena nova lozinka" />
                    {/* <ButtonBar
                        id1="button1"
                        buttonOneStyle="secondary"
                        buttonOneContent="Otkaži"
                        id2="button2"
                        buttonTwoStyle="disabled"
                        buttonTwoContent="Sačuvaj izmene"
                        onClick1={() => { console.log("Test"); }} /> */}
                </div>
                <div className="box-container">
                    <div className="box-header">
                        <p>Opšta podešavanja</p>
                    </div>
                    <DropDown
                        label="Odaberite zvuk notifikacije"
                        id="sound"
                        name="sound"
                        elements={{
                            default: "Select one",
                            sound1: "Sound 1",
                            sound2: "Sound 2"
                        }}
                        onClick={(event) => { console.log(event.target.value); }} />
                    <DropDown
                        label="Odaberite temu"
                        id="theme"
                        name="theme"
                        elements={{
                            default: "Select one",
                            theme1: "Theme 1",
                            theme2: "Theme 2"
                        }}
                        onClick={(event) => { console.log(event.target.value); }} />
                    <DropDown
                        label="Odaberite jezik"
                        id="lang"
                        name="lang"
                        elements={{
                            default: "Select one",
                            srb: "Srpski",
                            eng: "Engleski"
                        }}
                        onClick={(event) => { console.log(event.target.value); }} />
                    {/* <ButtonBar
                        buttonOneStyle="secondary"
                        buttonOneContent="Otkaži"
                        buttonTwoStyle="disabled"
                        buttonTwoContent="Sačuvaj izmene"
                        onClick1={() => { console.log("Test"); }} /> */}
                </div>
                <div className="box-container">
                    <div className="box-header">
                        <p>Debugging</p>
                    </div>
                    <div className="settings-page-box">
                        <div className="box-content box-content-debug">
                            <p>Enable/disable debugging</p>
                            <label htmlFor="toggle" className="switch">
                                <input type="checkbox" id="toggle" name="toggle" className="toggle-input" />
                                <span className="slider"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

export default Settings;
