import '../../style/common/drop-down.css'
import React from 'react';
/**
 * @property {string} dropdownWrapperSize
 * determines wrapper's width in percent
 * default value: 100
 * other values: 70 and 50
 * @property {string} dropdownContainerSize
 * determines containers width in percent
 * default value: 100
 * other values: 70 and 50
 * @property {string} titleContent
 * adds title to a dropdown
 * @property {string} label
 * describes what dropdown is about
 * @property {string} id
 * specifies the unique id of an element
 * @property {string} name
 * specifies the name of a dropdown menu
 * should be the same as id
 * @property {string} htmlFor
 * refers to the id of the element dropdown is associated with
 * should be the same as id
 * @property {boolean} dropdownDisabled
 * disables the dropdown- it cannot be clicked
 * default value: false
 * @property {boolean} dropdownRed
 * adds red border and red background to a dropdown
 * default value: false
 * @property {object} elements
 * specifies the elements of a dropdown
 * @property {function} onClick
 * function executed when clicked on menu element
 */
var createReactClass = require('create-react-class');
var DropDown = createReactClass({
    getInitialState: function () {
        return this.getInitObject();
    },
    getInitObject: function () {
        let _dropdownDisabled = "dropdown-disabled";
        let _dropdownRed = "dropdown-red";
        return {
            dropdownWrapperSize: this.getWrapperSize(this.props.dropdownWrapperSize),
            dropdownContainerSize: this.getContainerSize(this.props.dropdownContainerSize),
            dropdownDisabled: this.props.dropdownDisabled ? _dropdownDisabled : "",
            dropdownRed: this.props.dropdownRed ? _dropdownRed : ""
        };
    },
    getWrapperSize: function (wrapperSize) {
        switch (wrapperSize) {
            case "70":
                return "dropdown-wrapper-m";
            case "50":
                return "dropdown-wrapper-s";
            default:
                return "dropdown-wrapper-l";
        }
    },
    getContainerSize: function (containerSize) {
        switch (containerSize) {
            case "70":
                return "dropdown-container-m";
            case "50":
                return "dropdown-container-s";
            default:
                return "dropdown-container-l";
        }
    },
    componentDidUpdate: function (prevProps) {
        if (prevProps.dropdownWrapperSize !== this.props.dropdownWrapperSize ||
            prevProps.dropdownContainerSize !== this.props.dropdownContainerSize ||
            prevProps.dropdownDisabled !== this.props.dropdownDisabled ||
            prevProps.dropdownRed !== this.props.dropdownRed) {
            this.setState(this.getInitObject());
        }
    },
    render: function () {
        return (
            <div className={("dropdown-wrapper " + (this.state.dropdownWrapperSize))}>
                {this.props.titleContent ? <p className="dropdown-title">{this.props.titleContent}</p> : null}
                <div className={("dropdown-container " + (this.state.dropdownContainerSize))}>
                    <label className="dropdown-label" htmlFor={(this.props.htmlFor)}>
                        {this.props.label}
                    </label>
                    <select onClick={this.props.onClick} name={this.props.name} id={this.props.id} className={"dropdown-menu " + (this.state.dropdownDisabled) + " " + (this.state.dropdownRed)}>
                        {(this.props.elements) ? (Object.keys(this.props.elements)).map((elementKey) => {
                            const elementValue = this.props.elements[elementKey];
                            return (
                                <option value={elementValue} id={elementKey}>
                                    {elementValue}
                                </option>
                            );
                        }) : null}
                    </select>
                </div>
            </div>
        );
    }
});

export default DropDown;