import '../../style/verification.css';
// import AuthenticationManager from '../../database/authentication.js'
var createReactClass = require('create-react-class');
var React = require('react');

var EmailVerificationFail = createReactClass({
    onClick: function (event) {
        switch (event.target.name) {
            case "login_back":
                if (this.props.initiateLogOut) {
                    this.props.initiateLogOut();
                } else {
                    console.log("Logout function is not defined!");
                }
                break;
            case "resend_email":
                if (this.props.initiateEmailResend) {
                    this.props.initiateEmailResend();
                } else {
                    console.log("Email resend function is not defined!");
                }
                break;
            default:
                break;
        }
    },
    render: function () {
        return (
            <div class='verification-fail-container border-radius border-gray'>
                <div class='verification-fail-content'>
                    <div class="verification-fail-header">
                        <h3>Potvrda e-mail adrese</h3>
                    </div>
                    <div class="verification-fail-body">
                        <p>Vaša registracija je skoro gotova. Da bi je završili, molimo Vas da kliknete na link, koji Vam je upravo poslat na e-mail. </p>
                        <p>Nakon verifikacije, osvežite stranicu.</p>
                        <a id="resend_email" href name="resend_email" onClick={this.onClick} className="link-to-terms">Kliknite ovde</a> ukoliko e-mail za verifikaciju nije pristigao. Molimo Vas da proverite spam.
                        <br />
                        <a id="login_back" href name="login_back" onClick={this.onClick} className="link-to-terms">Povratak na stranicu za logovanje.</a>
                    </div>
                </div>
            </div>
        );
    }
});

export default EmailVerificationFail;