import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
require("firebase/functions");

let relMode = false;
const connectDirectlyToRel = false;

const isRel = function () {
  return relMode;
};

function getFirebaseConfig() {
  const domain = String(window.location.href);
  if (!connectDirectlyToRel && ((domain.includes("test")) || (domain.includes("localhost")))) {
    relMode = false;
    return {
      apiKey: "AIzaSyAaSSlFybHU5l1y7ATcRcvf9FsnxNEBiPQ",
      authDomain: "demango-rel.firebaseapp.com",
      projectId: "demango-rel",
      storageBucket: "demango-rel.appspot.com",
      messagingSenderId: "997893809195",
      appId: "1:997893809195:web:1b7310761f48d8128c366e",
      measurementId: "G-MN084YDJDJ"
    };
  }
  else {
    relMode = true;
    return {
      apiKey: "AIzaSyDnXilLrFPQq0fwoVsdny98KIfvA_wLM6U",
      authDomain: "demango-f56c2.firebaseapp.com",
      projectId: "demango-f56c2",
      storageBucket: "demango-f56c2.appspot.com",
      messagingSenderId: "614935564692",
      appId: "1:614935564692:web:32069e8623199e17b8bf81",
      measurementId: "G-H6Y7W2LJT1"
    };
  }
}

firebase.initializeApp(getFirebaseConfig());
var functions = firebase.functions();

const storage = firebase.storage();

const db = firebase.firestore();

export { isRel, functions, db, storage, firebase as default };
