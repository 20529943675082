import '../../style/loading-div.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

function loadingDiv() {
    return (
        <div class="loading-wrap">
            <div class="loading-wrapper">
                <div class="spinner-border m-5 my-spinner-customization" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </div>
    );
}

export default loadingDiv