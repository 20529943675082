function dateToFullString(dateTime) {
    if (dateTime) {
        const date = new Date(dateTime);
        const mount = Number(date.getMonth()) + 1;
        return "" +
            (date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate()) +
            "." +
            (mount < 10 ? ("0" + mount) : mount) +
            "." +
            (date.getFullYear() < 10 ? ("0" + date.getFullYear()) : date.getFullYear()) +
            ". " +
            (date.getHours() < 10 ? ("0" + date.getHours()) : date.getHours()) +
            ":" +
            (date.getMinutes() < 10 ? ("0" + date.getMinutes()) : date.getMinutes());
    }
    else {
        console.log("Date number cannot be null!");
        return "Date Error"
    }
}


export default dateToFullString