import * as emailjs from "emailjs-com";

class EmailService {
    /**
     * Sends email with bug report.
     * @param {*Contains user attributes and bug attributes.} bugObject 
     * @param {*Function will be executed after successful email send.} onSendFinish 
     */
    static emailBug(bugObject, onSendFinish) {
        emailjs.send("service_demango_bugs", "template_bug_report", bugObject, "user_j1wlRqLLOprmvIsEz5esw")
            .then((response) => {
                if (onSendFinish) {
                    onSendFinish();
                }
            },
                (err) => {
                    console.log(err);
                }
            );
    }
    /**
     * Sends email with survey information. 
     * @param {*Contains user attributes and bug attributes.} surveyObject
     * @param {*Function will be executed after successful email send.} onSendFinish
     */
    static emailSurvey01(surveyObject, onSendFinish) {
        emailjs.send("service_demango_bugs", "template_survey01", surveyObject, "user_j1wlRqLLOprmvIsEz5esw")
            .then((response) => {
                if (onSendFinish) {
                    onSendFinish();
                }
            },
                (err) => {
                    console.log(err);
                }
            );
    }

}

export default EmailService;