import '../../style/app-connection-state.css';
import React from 'react';
import { isRel } from '../../database/firebase';

var createReactClass = require('create-react-class');

var AppConnectionState = createReactClass({
    getInitialState: function () {
        return {
            hideMe: false,
        };
    },
    componentDidMount: function () {
        window.scrollTo(0, 0);
    },
    onClick: function (event) {
        this.setState({ hideMe: true });
        this.autoShow(Number(event.target.value));
    },
    autoShow: function (minutes) {
        this.sleep(minutes * 60000).then(() => { this.setState({ hideMe: false }); })
    },
    sleep: async function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    render: function () {
        if (isRel() || this.state.hideMe) {
            return (null);
        }
        else {
            return (
                <>
                    <div className="login-page-testing-info">
                        <div className="blink-text">
                            <p>TESTING! This app is connected to test DB.</p>
                        </div>
                        <div className="hide-me">
                            <label>Hide me for</label>
                            <select name="min" id="min" className="minutes-dropdown" onChange={this.onClick}>
                                <option value="0">how long?</option>
                                <option value="1">1 minute</option>
                                <option value="3">3 minutes</option>
                                <option value="5">5 minutes</option>
                                <option value="8">8 minutes</option>
                                <option value="13">13 minutes</option>
                                <option value="21">21 minutes</option>
                            </select>
                        </div>
                    </div>
                </>
            );
        }

    }
});

export default AppConnectionState;
